.pos-token {
  margin: 1px;
  background-color: #E5E5E5;
  white-space: pre-wrap;
}

.pos-token:hover {
  cursor: pointer;
  color: black;
}

.pos-gap {
  margin: 1px;
  color: lightgrey;
}

.pos-gap:hover {
  cursor: not-allowed;
}

/* border-bottom: 5px solid rgb(5, 130, 15); */

/* ADJ - turquoise */
.adj {
  background-color: rgb(174, 230, 227);
}

/* ADP - orange */
.adp {
  background-color: rgb(235, 215, 178);
}

/* ADV - red */
.adv {
  background-color: rgb(219, 151, 151);
}

/* AUX - green */
.aux {
  background-color: rgb(185, 219, 182);
}

/* CCONJ - yellow */
.cconj {
  background-color: rgb(235, 237, 183);
}

/* DET - orange */
.det {
  background-color: rgb(222, 182, 109);
}

/* INTJ - blue */
.intj {
  background-color: rgb(160, 181, 235);
}

/* NOUN - turquoise */
.noun {
  background-color: rgb(148, 214, 211);
}

/* NUM - purple */
.num {
  background-color: rgb(228, 192, 235);
}

/* PART - orange */
.part {
  background-color: rgb(222, 200, 160);
}

/* PRON - light blue */
.pron {
  background-color: rgb(157, 212, 224);
}

/* PROPN - turquoise */
.propn {
  background-color: rgb(148, 214, 211);
}

/* PUNCT - grey_green */
.punct {
  background-color: rgb(173, 186, 174);
}

/* SCONJ - yellow */
.sconj {
  background-color: rgb(235, 237, 183);
}

/* SYM - grey_green */
.sym {
  background-color: rgb(173, 186, 174);
}

/* VERB - red */
.verb {
  background-color: rgb(217, 165, 165);
}

/* X - grey_green */
.x {
  background-color: rgb(173, 186, 174);
}
