body {
  padding: 0px;
  margin: 0px;
}

.tagging-area {
  margin: 10px;
}

.tagging-text {
  text-align: left;
  overflow-y: scroll;
  border: 1px solid black;
  width: 100%;
  flex-wrap: wrap;
  padding-bottom: 100px;
  height: 300px;
}

.buttons {
  text-align: right;
}

.button {
  margin: 5px;
}