.token {
  margin: 1px;
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

.auto {
  background-color: #AED6F1;
}

.manual {
  background-color: #82E0AA;
}