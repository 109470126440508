@import url(https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css);
@import url("https://fonts.googleapis.com/css?family=Roboto");

html,
body,
* {
    box-sizing: border-box;
    font-size: 16px;
}

html,
body {
    height: 100%;
    text-align: center;
}

body {
    background: #f8f8f8;
}

h2 {
    font-family: "Roboto", sans-serif;
    font-size: 26px;
    line-height: 1;
    color: #a88f32;
    margin-bottom: 0;
}

p {
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    color: #5f6982;
}

.uploader {
    display: block;
    clear: both;
    margin: 0 auto;
    width: 100%;
    max-width: 600px;
}

.uploader label {
    float: left;
    clear: both;
    width: 100%;
    padding: 2rem 1.5rem;
    text-align: center;
    background: #fff;
    border-radius: 7px;
    border: 3px solid #eee;
    transition: all 0.2s ease;
    user-select: none;
}

.uploader label:hover {
    border-color: #b59728;
}

.uploader label.hover {
    border: 3px solid #a88f32;
    box-shadow: inset 0 0 0 6px #eee;
}

.uploader label.hover #start i.fa {
    transform: scale(0.8);
    opacity: 0.3;
}

.uploader #start {
    float: left;
    clear: both;
    width: 100%;
}

.uploader #start.hidden {
    display: none;
}

.uploader #start i.fa {
    font-size: 50px;
    margin-bottom: 1rem;
    transition: all 0.2s ease-in-out;
}

.uploader #response {
    float: left;
    clear: both;
    width: 100%;
}

.uploader #response.hidden {
    display: none;
}

.uploader #response #messages {
    margin-bottom: 0.5rem;
}

.uploader #file-image {
    display: inline;
    margin: 0 auto 0.5rem auto;
    width: auto;
    height: auto;
    max-width: 180px;
}

.uploader #file-image.hidden {
    display: none;
}

.uploader #notimage {
    display: block;
    float: left;
    clear: both;
    width: 100%;
}

.uploader #notimage.hidden {
    display: none;
}

.uploader progress,
.uploader .progress {
    display: inline;
    clear: both;
    margin: 0 auto;
    width: 100%;
    max-width: 180px;
    height: 8px;
    border: 0;
    border-radius: 4px;
    background-color: #eee;
    overflow: hidden;
}

.uploader .progress[value]::-webkit-progress-bar {
    border-radius: 4px;
    background-color: #eee;
}

.uploader .progress[value]::-webkit-progress-value {
    background: linear-gradient(to right, #a38e41 0%, #a88f32 50%);
    border-radius: 4px;
}

.uploader .progress[value]::-moz-progress-bar {
    background: linear-gradient(to right, #a88f32 0%, #a38e41 50%);
    border-radius: 4px;
}

/* .uploader input[type="file"] {
  display: none;
} */
.uploader div {
    margin: 0 0 0.5rem 0;
    color: #adab1d;
}

.uploader .btn {
    display: inline-block;
    margin: 0.5rem 0.5rem 1rem 0.5rem;
    clear: both;
    font-family: inherit;
    font-weight: 700;
    font-size: 14px;
    text-decoration: none;
    text-transform: initial;
    border: none;
    border-radius: 0.2rem;
    outline: none;
    padding: 0 1rem;
    height: 36px;
    line-height: 36px;
    color: #fff;
    transition: all 0.2s ease-in-out;
    box-sizing: border-box;
    background: #b8940f;
    border-color: #a88f32;
    cursor: pointer;
}